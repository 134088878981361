import { ParagraphLarge } from 'src/components/atoms/DesignSystem/Typography/Typography'
import { Heading2 } from 'src/components/atoms/Typography'
import {
  CmsCtaButton,
  CmsCtaButtonProps
} from 'src/components/molecules/CmsCtaButton'

export interface CtaBandProps {
  heading?: string
  subheading?: string
  color: string
  buttons?: CmsCtaButtonProps[] | JSX.Element
}

export const CtaBandSection = ({
  heading,
  subheading,
  color,
  buttons
}: CtaBandProps) => {
  return (
    <div
      className={`text-center p-10 rounded bg-${color} ${
        color === 'mostlyGreen' ? '!text-white' : ''
      }`}
    >
      <Heading2>{heading}</Heading2>
      <ParagraphLarge className='pb-4'>{subheading}</ParagraphLarge>
      {Array.isArray(buttons)
        ? buttons?.map((ctaButton: CmsCtaButtonProps, idx: any) => (
            <CmsCtaButton
              size='large'
              key={`ctabutton-${idx}`}
              {...ctaButton}
              color={idx === 0 ? 'primary' : 'white'}
            />
          ))
        : buttons}
    </div>
  )
}
