import * as Yup from 'yup'

export const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Must be a valid email'),
  message: Yup.string().required('Interest is required'),
  organisation: Yup.string().required('Organisation is required')
})
