import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import { useMemo } from 'react'

import { Button } from 'src/components/atoms/Button'
import { ButtonLink } from 'src/components/atoms/Link'
import { SanityImage } from 'src/components/atoms/SanityImage'

import { Cta, FocusPerson } from 'src/config/generated/sanitySchema'
import { useMessenger } from 'src/hooks/useMessenger'
import { getSanityImageUrl } from 'src/utils/sanity'

import { BusinessContact } from '../../BusinessContact'

type BusinessFooterSectionProps = {
  title: string
  backgroundImage: SanityImageSource
  focusPerson: FocusPerson
  cta?: Cta
}

const BusinessFooterSection = ({
  focusPerson,
  backgroundImage,
  cta
}: BusinessFooterSectionProps) => {
  const backgroundImageUrl = getSanityImageUrl(backgroundImage)
  const { showNewMessage } = useMessenger()

  const ctaComponent = useMemo(() => {
    if (!cta) return null

    if (cta.route === 'showIntercom') {
      return (
        <Button
          size='large'
          color={cta.color}
          variant={cta.variant}
          onClick={showNewMessage}
          className='mt-[10px] sm:self-auto self-stretch h-[48px]'
        >
          {cta.title}
        </Button>
      )
    }

    return (
      <ButtonLink
        size='large'
        color={cta.color}
        variant={cta.variant}
        href={cta.route! || cta.link!}
        isExternal={!!cta.link}
        className='mt-[10px] sm:self-auto self-stretch h-[48px]'
      >
        {cta.title}
      </ButtonLink>
    )
  }, [cta, showNewMessage])

  return (
    <div
      className='bg-white bg-left-top bg-no-repeat bg-cover flex flex-col lg:flex-row gap-5 px-[15px] py-5 lg:py-[100px] justify-center'
      style={{
        backgroundImage: `url(${backgroundImageUrl})`
      }}
    >
      <div className='flex-1 text-white bg-black/70 flex flex-col lg:max-w-[650px] px-5 md:px-[60px] py-10'>
        <BusinessContact />
      </div>
      <div className='flex-1 text-white text-center bg-black/70 flex flex-col gap-5 justify-center lg:max-w-[460px] items-center p-5 md:px-[60px] md:py-10 py-[50px]'>
        <span className='font-display text-[18px] leading-[24px] md:text-[30px] md:leading-[40px] font-black'>
          “{focusPerson.quote}”
        </span>
        <span className='text-[18px] leading-[24px] md:text-[20px] md:leading-[30px] font-medium'>
          {focusPerson.title}
        </span>
        <div className='relative rounded-full w-[133px] h-[133px] overflow-hidden'>
          <SanityImage
            className='object-cover'
            alt={focusPerson.title}
            source={focusPerson.photo}
            fill
          />
        </div>
        {ctaComponent}
      </div>
    </div>
  )
}

export default BusinessFooterSection
