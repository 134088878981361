import { useMemo } from 'react'

import { ButtonLink } from 'src/components/atoms/Link'
import { SanityImage } from 'src/components/atoms/SanityImage'
import { Heading2, ParagraphNoSpace } from 'src/components/atoms/Typography'

import { ImageTiles } from 'src/config/generated/sanitySchema'

type ImageTilesSectionProps = ImageTiles

const ImageTilesSection = ({
  title,
  description,
  ctas,
  eyebrowText,
  images
}: ImageTilesSectionProps) => {
  const gridClasses = useMemo(() => {
    switch (images?.length) {
      case 3:
        return 'grid-cols-2 grid-rows-2 lg:grid-cols-3 lg:grid-rows-1'
      case 4:
        return 'grid-cols-2 grid-rows-2 lg:grid-rows-1 lg:grid-cols-4'
      case 6:
        return 'grid-cols-2 grid-rows-3 lg:grid-cols-3 lg:grid-rows-2'
      case 8:
        return 'grid-cols-2 grid-rows-4 lg:grid-cols-4 lg:grid-rows-2'
      default:
        return 'grid-cols-2 lg:grid-cols-4'
    }
  }, [images])

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col !items-center mx-[30px]'>
        {title ? (
          <Heading2 className='!m-0 !mb-[10px] text-center !text-[26px] !leading-[28px] md:!text-[40px] md:!leading-[50px] !font-bold'>
            {title}
          </Heading2>
        ) : null}
        {description ? (
          <ParagraphNoSpace className='!mb-[10px] text-center !text-[16px] !leading-[24px] md:!text-[20px] md:!leading-[30px] !font-medium'>
            {description}
          </ParagraphNoSpace>
        ) : null}
      </div>
      <div className=' gap-5 flex flex-col items-center'>
        {eyebrowText ? (
          <span className='md:self-start !text-[16px] !leading-[18px] font-semibold text-[#999999]'>
            {eyebrowText}
          </span>
        ) : null}
        <div className={`w-full gap-5 grid ${gridClasses}`}>
          {images?.map((image, idx) => (
            <SanityImage
              className='w-full h-full'
              width={270}
              height={100}
              key={idx}
              alt={`image ${idx + 1}`}
              source={image}
            />
          ))}
        </div>
        {ctas && ctas.length ? (
          <div className='flex flex-row gap-[10px]'>
            {ctas?.map(cta => {
              const isExternal = !!cta.link

              return (
                <ButtonLink
                  key={cta._key}
                  className='!min-w-[120px]'
                  variant={cta.variant}
                  color={cta.color}
                  isExternal={isExternal}
                  href={isExternal ? cta.link! : cta.route!}
                  size='large'
                >
                  {cta.title}
                </ButtonLink>
              )
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ImageTilesSection
