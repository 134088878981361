import { Form, Formik } from 'formik'

import { Button } from 'src/components/atoms/Button'
import { ExternalLink } from 'src/components/atoms/Link'
import { LoadingSpinner } from 'src/components/atoms/LoadingSpinner'
import { ParagraphSmaller } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'

import { FormFieldTypes } from 'src/types/form'
import { inferMutationInput } from 'src/utils/trpc'

import { validationSchema } from './validationSchema'

type FormValuesType = Omit<inferMutationInput<'emails.enquiry'>, 'token'>

const INITIAL_VALUES: FormValuesType = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
  organisation: ''
}

export interface BusinessContactFormProps {
  isLoading: boolean
  onSubmit: (values: FormValuesType) => void
}

export const BusinessContactForm = ({
  isLoading,
  onSubmit
}: BusinessContactFormProps) => {
  const handleOnSubmit = (values: FormValuesType) => {
    if ('gtag' in window && typeof window.gtag === 'function')
      window.gtag('event', 'form_submission', {
        form_name: 'business_enquiry'
      })

    onSubmit(values)
  }
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <LoadingSpinner isLoading={isLoading}>
              <div className='flex flex-col gap-5'>
                <div className='flex flex-wrap justify-around md:flex-nowrap gap-5'>
                  <FormField
                    id='firstName'
                    type={FormFieldTypes.text}
                    label='First name'
                    formFieldClassName='bg-white'
                    labelClassName='text-white'
                    className='m-0'
                  />

                  <FormField
                    id='lastName'
                    type={FormFieldTypes.text}
                    label='Last name'
                    formFieldClassName='bg-white'
                    labelClassName='text-white'
                    className='m-0'
                  />
                </div>
                <div className='flex flex-wrap justify-around md:flex-nowrap gap-5'>
                  <FormField
                    id='email'
                    type={FormFieldTypes.text}
                    label='Email'
                    formFieldClassName='bg-white'
                    labelClassName='text-white'
                    className='m-0'
                  />
                  <FormField
                    id='phone'
                    type={FormFieldTypes.tel}
                    label='Telephone number'
                    formFieldClassName='bg-white'
                    labelClassName='text-white'
                    className='m-0'
                  />
                </div>
                <FormField
                  id='organisation'
                  type={FormFieldTypes.text}
                  label='Organisation'
                  className='m-0'
                  formFieldClassName='bg-white'
                  labelClassName='text-white'
                />
                <FormField
                  id='message'
                  label='How can we help?'
                  type={FormFieldTypes.textarea}
                  rows={3}
                  className='m-0'
                  formFieldClassName='bg-white'
                  labelClassName='text-white'
                  resize={false}
                />
                <ParagraphSmaller>
                  This site is protected by reCAPTCHA and the Google{' '}
                  <ExternalLink href='https://policies.google.com/privacy'>
                    Privacy Policy
                  </ExternalLink>{' '}
                  and{' '}
                  <ExternalLink href='https://policies.google.com/terms'>
                    Terms of Service
                  </ExternalLink>{' '}
                  apply.
                </ParagraphSmaller>
                <Button
                  size='large'
                  className='self-center'
                  disabled={isSubmitting}
                  type='submit'
                >
                  Submit
                </Button>
              </div>
            </LoadingSpinner>
          </Form>
        )
      }}
    </Formik>
  )
}
