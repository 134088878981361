import { useRef, useState } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import { Divider } from 'src/components/atoms/Divider'
import { IconButton } from 'src/components/atoms/IconButton'
import { ButtonLink } from 'src/components/atoms/Link'
import { SanityImage } from 'src/components/atoms/SanityImage'
import {
  Heading2,
  Heading2Display,
  Heading4,
  ParagraphNoSpace
} from 'src/components/atoms/Typography'

import { FeatureCarousel as FeatureCarouselType } from 'src/config/generated/sanitySchema'

type FeatureCarouselProps = Omit<FeatureCarouselType, '_type'>

function constrain(value: number, min: number, max: number): number {
  if (value < min) return min
  if (value > max) return max
  return value
}

const FeatureCarousel = ({
  title,
  content,
  cta,
  subtitle,
  direction
}: FeatureCarouselProps) => {
  const [selectedPlanterIdx, setSelectedPlanterIdx] = useState(0)
  const featureRefs = useRef<HTMLDivElement[]>([])

  const handleChevronClick = (dir: 'left' | 'right') => {
    const newIndex = constrain(
      dir === 'left' ? selectedPlanterIdx - 1 : selectedPlanterIdx + 1,
      0,
      content.length - 1
    )
    setSelectedPlanterIdx(newIndex)
    featureRefs.current?.[newIndex]?.scrollIntoView({
      block: 'nearest',
      inline: 'nearest'
    })
  }

  return (
    <div className='bg-white flex flex-col px-[15px]  md:px-[60px] gap-5 md:gap-10'>
      <div className='flex text-center flex-col gap-[10px] items-center'>
        <Heading2 className='!m-0 text-6 leading-[28px] md:text-[40px] md:leading-[50px] font-bold'>
          {title}
        </Heading2>
        {subtitle ? (
          <ParagraphNoSpace className='text-[18px] leading-[24px] md:text-[20px] md:leading-[30px] font-medium'>
            {subtitle}
          </ParagraphNoSpace>
        ) : null}
      </div>
      <div className='w-full'>
        <div className='overflow-x-hidden no-scrollbar overflow-y-hidden flex flex-row w-full snap-x'>
          {content?.map((feature, idx) => {
            return (
              <div
                key={idx}
                ref={(el: HTMLDivElement) => {
                  featureRefs.current[idx] = el
                }}
                className='h-full snap-start min-w-full'
              >
                <div
                  className={`flex flex-col-reverse lg:${
                    direction === 'contentFirst'
                      ? 'flex-row'
                      : 'flex-row-reverse'
                  } gap-5 md:gap-[60px]`}
                >
                  <div className='flex-1 flex flex-col gap-5 justify-between'>
                    <div
                      className={`flex-1 flex flex-col gap-10 ${
                        feature.stats?.length ? '' : 'justify-center'
                      }`}
                    >
                      <div>
                        {feature.quote ? (
                          <Heading2Display className='!m-0 !text-[24px] !leading-[33px] md:!text-[30px] md:!leading-[40px] !font-black !text-mostlyGreen'>
                            {feature.quote}
                          </Heading2Display>
                        ) : null}
                        {feature.quoteName ? (
                          <Heading4 className='!font-medium text-mostlyGreen pt-2'>
                            {feature.quoteName}
                          </Heading4>
                        ) : null}
                      </div>
                    </div>
                    <div className='flex flex-col gap-5 md:gap-10'>
                      <div className='flex flex-col gap-5'>
                        {feature.stats?.map(stat => (
                          <div
                            key={stat._key}
                            className='px-[10px] border-l-[5px] text-mostlyGreen border-l-mostlyGreen flex flex-col'
                          >
                            <span className='font-bold tracking-tight text-[32px]  leading-none'>
                              {stat.title}
                            </span>
                            <span className='text-[14px] font-medium'>
                              {stat.subtitle}
                            </span>
                          </div>
                        ))}
                      </div>
                      {cta || feature.stats?.length ? (
                        <Divider className='!m-0' />
                      ) : null}
                      <div className='flex flex-row justify-between'>
                        {cta ? (
                          <ButtonLink
                            size='large'
                            color={cta.color}
                            variant={cta.variant}
                            href={cta.route! || cta.link!}
                            isExternal={!!cta.route}
                          >
                            {cta.title}
                          </ButtonLink>
                        ) : null}
                        <div>
                          {content.length > 1 ? (
                            <div className='flex flex-row gap-[10px]'>
                              <IconButton
                                icon={MdChevronLeft}
                                disabled={selectedPlanterIdx === 0}
                                size='large'
                                color='primary-inverted'
                                onClick={() => handleChevronClick('left')}
                                ariaLabel='Previous'
                              />
                              <IconButton
                                icon={MdChevronRight}
                                disabled={
                                  selectedPlanterIdx === content.length - 1
                                }
                                color='primary-inverted'
                                size='large'
                                onClick={() => handleChevronClick('right')}
                                ariaLabel='Next'
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full h-full flex-1 aspect-square relative'>
                    <SanityImage
                      className='aspect-square object-cover w-full h-full'
                      alt={feature.title!}
                      source={feature.posterImage}
                      fill
                    />
                    {feature.avatar ? (
                      <div className='flex flex-row gap-[10px] p-5 pr-10 bg-black min-w-1/2 absolute text-white bottom-[10px]'>
                        <div className='relative w-[50px] h-[50px] rounded-[18px] overflow-hidden'>
                          <SanityImage
                            className='object-cover'
                            source={feature.avatar}
                            alt='avatar'
                            fill
                          />
                        </div>
                        <div className='flex flex-col'>
                          <span className='text-[16px] leading-[24px] font-semibold'>
                            {feature.title}
                          </span>
                          <span className='text-[16px] leading-[24px] font-medium'>
                            {feature.subtitle}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FeatureCarousel
