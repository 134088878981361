import { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Heading2, Paragraph } from 'src/components/atoms/Typography'

import { inferMutationInput, trpc } from 'src/utils/trpc'

import { BusinessContactForm } from '../forms/BusinessContactForm'

export const BusinessContact = () => {
  const { mutate, isLoading, isSuccess, error } = trpc.useMutation([
    'emails.enquiry'
  ])
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(
    async (values: Omit<inferMutationInput<'emails.enquiry'>, 'token'>) => {
      if (!executeRecaptcha) {
        // Execute recaptcha not yet available
        return
      }

      const token = await executeRecaptcha('business_enquiry')
      mutate({ ...values, type: 'business', token })
    },
    [executeRecaptcha, mutate]
  )
  if (error)
    return (
      <>
        <Heading2>Something went wrong!</Heading2>
        <Paragraph>{error.message}</Paragraph>
      </>
    )
  return (
    <div className='flex flex-col gap-5'>
      <Heading2 className='!m-0 md:!text-[40px] md:!leading-[50px] !text-[26px] !leading-[34px] !font-bold'>
        Get in touch
      </Heading2>
      {!isSuccess ? (
        <BusinessContactForm
          onSubmit={handleReCaptchaVerify}
          isLoading={isLoading}
        />
      ) : (
        <Paragraph>
          We&apos;ve received your enquiry, we&apos;ll aim to get back to you as
          soon as possible.
        </Paragraph>
      )}
    </div>
  )
}
