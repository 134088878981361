import { useMemo } from 'react'

import { Image } from 'src/components/atoms/Image'
import { ButtonLink } from 'src/components/atoms/Link'
import { SanityImage } from 'src/components/atoms/SanityImage'
import { Heading2, Heading3, Paragraph } from 'src/components/atoms/Typography'
import { ImageListResult } from 'src/components/layouts/Page/SanityContentPage/types'

type ImageListSectionProps = Omit<ImageListResult, '_type'>

const ImageListSection = ({
  heading,
  headingSize,
  image,
  ctas,
  direction = 'imageFirst',
  eyebrowText,
  items,
  location,
  subHeading,
  listVariant = 'tick',
  showLocationPin = true,
  sizeVariant = 'normal'
}: ImageListSectionProps) => {
  const headingComponent = useMemo(() => {
    switch (headingSize) {
      case 'h3':
        return (
          <Heading3 className='!m-0 !text-[24px] !leading-[30px] !font-bold md:!text-[28px] md:!leading-[39px] md:!font-semibold whitespace-pre-line'>
            {heading}
          </Heading3>
        )
      case 'h2':
      default:
        return (
          <Heading2 className='!m-0 !text-[26px] !leading-[34px] md:!text-[40px] md:!leading-[50px] !font-bold whitespace-pre-line'>
            {heading}
          </Heading2>
        )
    }
  }, [headingSize, heading])

  const listClasses = useMemo(() => {
    let className = 'font-medium'
    if (listVariant === 'numbered') {
      className = `${className} list-numbered`
    } else if (listVariant === 'tick') {
      className = `${className} list-check`
    }

    if (sizeVariant === 'normal') {
      className = `${className} large`
    }

    return className
  }, [listVariant, sizeVariant])

  return (
    <div className='bg-white flex flex-col gap-5 md:gap-10'>
      <div className='w-full'>
        <div className='overflow-x-hidden no-scrollbar overflow-y-hidden flex flex-row w-full snap-x'>
          <div
            className={`h-full snap-start min-w-full flex flex-col lg:${
              direction === 'imageFirst' ? 'flex-row' : 'flex-row-reverse'
            } gap-5 md:gap-10 lg:gap-20`}
          >
            <div className='flex-1 overflow-hidden rounded-[5px] self-auto lg:self-center h-full aspect-square relative'>
              <SanityImage
                className='object-cover aspect-square'
                alt='Impact dashboard sheet'
                source={image}
                fill
              />
              {location ? (
                <span className='text-center absolute mx-auto w-auto md:w-fit left-5 right-5 bottom-10 text-[14px] leading-[20px] font-semibold bg-white opacity-80 px-[10px] py-2 rounded-[5px]'>
                  {showLocationPin ? (
                    <Image
                      className='inline mr-2'
                      alt='pin'
                      width={17}
                      height={21}
                      src='/img/icons/treeLocationPin.svg'
                    />
                  ) : null}
                  {location}
                </span>
              ) : null}
            </div>
            <div className='flex-1 flex flex-col gap-5 md:gap-10 md:justify-center'>
              <div className='flex flex-col'>
                {eyebrowText ? (
                  <Paragraph className='!m-0 !mb-[10px] !text-[14px] !leading-[20px] font-bold text-[#999999]'>
                    {eyebrowText}
                  </Paragraph>
                ) : null}
                <div className='last:mb-0 mb-[10px] md:mb-5'>
                  {heading ? headingComponent : null}
                </div>
                {subHeading ? (
                  <Paragraph className='!m-0 !font-medium !text-[16px] !leading-[24px] md:!text-[20px] md:!leading-[30px]'>
                    {subHeading}
                  </Paragraph>
                ) : null}
              </div>
              <div className='flex flex-col gap-5'>
                <ul className={`${listClasses}`}>
                  {items?.map((item, idx) => (
                    <li key={`item_${idx}`}>{item}</li>
                  ))}
                </ul>
              </div>
              {ctas?.length ? (
                <div className='flex flex-col md:flex-row gap-[10px] md:-mt-5'>
                  {ctas.map((cta, idx) => {
                    const isExternal = cta.externalUrl
                    return (
                      <ButtonLink
                        href={
                          isExternal ? cta.externalUrl! : cta.internalLink!.path
                        }
                        key={cta.text}
                        color='primary'
                        variant={idx === 0 ? 'contained' : 'outlined'}
                        size='large'
                      >
                        {cta.text}
                      </ButtonLink>
                    )
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageListSection
