import { useMemo } from 'react'

import { Spacer } from 'src/config/generated/sanitySchema'

const SectionSpacer = ({ size }: Spacer) => {
  const spacing = useMemo(() => {
    switch (size) {
      case 'small':
        return 'h-[10px]'
      case 'medium':
        return 'h-[10px] md:h-5'
      case 'large':
        return 'h-5 md:h-10'
      case 'extraLarge':
        return 'h-[50px] md:h-[100px]'
      default:
        return 'h-[10px] md:h-5'
    }
  }, [size])

  return <div className={`w-full ${spacing}`} />
}

export default SectionSpacer
