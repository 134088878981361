import { ButtonLink } from 'src/components/atoms/Link'
import { Heading2, ParagraphNoSpace } from 'src/components/atoms/Typography'

import { Heading } from 'src/config/generated/sanitySchema'

type StandaloneHeadingProps = Heading

const StandaloneHeading = ({
  heading,
  eyebrowText,
  subHeading,
  cta,
  alignment = 'left'
}: StandaloneHeadingProps) => {
  return (
    <div
      className={`flex flex-col mb-[10px] md:mb-5 ${
        alignment === 'left' ? 'items-start' : 'items-center'
      }`}
    >
      {eyebrowText ? (
        <span className='text-[#999999] !font-bold !text-[14px] !leading[20px] !mb-[10px]'>
          {eyebrowText}
        </span>
      ) : null}
      {heading ? (
        <Heading2
          className={`${
            alignment === 'left' ? 'text-left' : 'text-center'
          } !m-0 !font-bold text-[26px] leading-[34px] md:!text-[40px] md:!leading-[50px] !text-charcoal !mb-[10px] md:!mb-5`}
        >
          {heading}
        </Heading2>
      ) : null}
      {subHeading ? (
        <ParagraphNoSpace
          className={`${
            alignment === 'left' ? 'text-left' : 'text-center'
          } !m-0 !font-medium !text-[16px] !leading-[24px] md:!text-[20px] md:!leading-[30px]`}
        >
          {subHeading}
        </ParagraphNoSpace>
      ) : null}
      {cta ? (
        <ButtonLink
          className='mt-[10px] md:!mt-5'
          href={(cta.route || cta.link)!}
          color={cta.color}
          variant={cta.variant}
          size='large'
        >
          {cta.title}
        </ButtonLink>
      ) : null}
    </div>
  )
}

export default StandaloneHeading
