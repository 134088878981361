import { CarbonCalculatorWidget } from 'src/components/molecules/CarbonCalculatorWidget'

type BusinessCarbonCalculatorSectionProps = {
  showExamples?: boolean
}

const BusinessCarbonCalculatorSection = ({
  showExamples
}: BusinessCarbonCalculatorSectionProps) => {
  return <CarbonCalculatorWidget showExamples={showExamples} />
}

export default BusinessCarbonCalculatorSection
