import { PropsWithChildren, useCallback } from 'react'

import { InternalLink } from 'src/components/atoms/Link'
import { LoadingSpinner } from 'src/components/atoms/LoadingSpinner'
import { Heading2, ParagraphIntro } from 'src/components/atoms/Typography'
import {
  FullWidthContainer,
  FullWidthContainerBackgroundColor
} from 'src/components/layouts/Container'
import BlogPosts from 'src/components/molecules/BlogPosts/BlogPosts'
import { BlogPostBackgroundColor } from 'src/components/molecules/BlogPosts/types'

import { blogPath } from 'src/config/paths'
import { trpc } from 'src/utils/trpc'

type NewsAndStoriesSectionProps = {
  backgroundColor?: BlogPostBackgroundColor
  featuredOn?: string
  title?: string
}

export const FeaturedPosts = ({
  backgroundColor = BlogPostBackgroundColor.white,
  featuredOn,
  title,
  subTitle,
  fullWidth = true
}: FeaturedPostsProps) => {
  const { data: newsAndStories, isLoading } = trpc.useQuery([
    'sanity.getPosts',
    {
      category: 'all',
      year: 'all',
      limit: 3,
      featuredOn
    }
  ])

  const Container = useCallback(
    ({ children }: PropsWithChildren) => {
      const backgroundColorMap: {
        [key in BlogPostBackgroundColor]: FullWidthContainerBackgroundColor
      } = {
        aquaSpring: FullWidthContainerBackgroundColor.aquaSpring,
        linen: FullWidthContainerBackgroundColor.linen,
        olivine: FullWidthContainerBackgroundColor.olivine,
        white: FullWidthContainerBackgroundColor.white
      }
      if (fullWidth) {
        return (
          <FullWidthContainer
            backgroundColor={backgroundColorMap[backgroundColor]}
            id='section-news'
            className='lg:py-[100px]'
          >
            {children}
          </FullWidthContainer>
        )
      }
      return <div>{children}</div>
    },
    [backgroundColor, fullWidth]
  )

  return (
    <Container>
      {title ? <Heading2>{title}</Heading2> : null}
      {subTitle}
      <LoadingSpinner isLoading={isLoading}>
        <BlogPosts
          posts={newsAndStories?.data}
          backgroundColor={backgroundColor}
        />
      </LoadingSpinner>
    </Container>
  )
}

export const NewsAndStoriesSection = ({
  backgroundColor = BlogPostBackgroundColor.white,
  featuredOn,
  title = 'News and stories'
}: NewsAndStoriesSectionProps) => {
  return (
    <FeaturedPosts
      backgroundColor={backgroundColor}
      title={title}
      featuredOn={featuredOn}
      subTitle={
        <ParagraphIntro className='mb-[50px]'>
          The latest articles, advice and updates from Trees That Count and the
          network of amazing planting projects across Aotearoa New Zealand.
          Discover all things native trees, biodiversity and climate action.{' '}
          <InternalLink
            color='black'
            className='underline hover:no-underline hover:text-black'
            href={blogPath()}
          >
            View all stories
          </InternalLink>
        </ParagraphIntro>
      }
    />
  )
}

type FeaturedPostsProps = {
  backgroundColor?: BlogPostBackgroundColor
  featuredOn?: string
  title?: string
  subTitle?: string | JSX.Element
  fullWidth?: boolean
}
