import { PropsWithChildren } from 'react'

import colors from 'src/styles/theme/colors'

type TypographyLevel =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body'
  | 'intro'
  | 'caption'
  | 'text'
  | 'quote'
  | 'label'

const levelMap: {
  [key in TypographyLevel]: { element: string; className: string }
} = {
  h1: {
    element: 'h1',
    className:
      'font-bold leading-[60px] md:leading-[64px] text-[50px] md:text-[64px]'
  },
  h2: { element: 'h2', className: '' },
  h3: { element: 'h3', className: '' },
  h4: { element: 'h4', className: '' },
  h5: { element: 'h5', className: '' },
  h6: { element: 'h6', className: '' },
  body: { element: 'p', className: '' },
  intro: { element: 'p', className: '' },
  caption: { element: 'span', className: '' },
  text: { element: 'span', className: '' },
  quote: { element: 'span', className: '' },
  label: { element: 'span', className: '' }
}

type TypographyProps = PropsWithChildren & {
  level?: TypographyLevel
  className?: string
  color?: keyof typeof colors
}

const Typography = ({
  children,
  className,
  level = 'body',
  color = 'shark'
}: TypographyProps) => {
  const config = levelMap[level]
  const Element = config.element as any

  const baseClassName = '!m-0'

  return (
    <Element
      className={`text-[${color}] ${config.className} ${baseClassName} ${className}`}
    >
      {children}
    </Element>
  )
}

export default Typography

type TextProps = PropsWithChildren<{
  className?: string
}>

export const Heading3 = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='h3'
      className={`font-body font-semibold text-[20px] leading-[28px] md:text-[24px] md:leading-[34px] lg:text-[28px] lg:leading-[39px] ${className}`}
    >
      {children}
    </Typography>
  )
}

export const Heading3Display = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='h3'
      className={`font-sans font-bold text-[24px] leading-[34px] md:text-[44px] md:leading-[44px] lg:text-[50px] lg:leading-[45px] ${className}`}
    >
      {children}
    </Typography>
  )
}

export const Heading4 = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='h4'
      className={`font-body font-bold text-[18px] leading-[25px] lg:text-[20px] lg:leading-[26px] ${className}`}
    >
      {children}
    </Typography>
  )
}

export const Heading6 = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='h6'
      className={`font-body font-semibold text-[16px] leading-[16px] ${className}`}
    >
      {children}
    </Typography>
  )
}

export const Paragraph = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='body'
      className={`font-body text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] ${className}`}
    >
      {children}
    </Typography>
  )
}

export const ParagraphSmall = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='body'
      className={`font-body text-[14px] leading-[20px] ${className}`}
    >
      {children}
    </Typography>
  )
}

export const ParagraphLarge = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='body'
      className={`font-body font-medium text-[18px] leading-[24px] md:text-[20px] md:leading-[30px] ${className}`}
    >
      {children}
    </Typography>
  )
}

export const Span = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='text'
      className={`font-sans text-[16px] leading-[24px] ${className}`}
    >
      {children}
    </Typography>
  )
}

export const SpanBold = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='text'
      className={`font-sans font-bold text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] ${className}`}
    >
      {children}
    </Typography>
  )
}

export const ListTitle = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='text'
      className={`font-body text-[14px] leading-[16px] font-semibold ${className}`}
    >
      {children}
    </Typography>
  )
}

export const TextDisplay = ({ children, className = '' }: TextProps) => {
  return (
    <Typography
      level='text'
      className={`font-displayAlt font-[500] text-[24px] leading-[34px] md:text-[44px] md:leading-[44px] lg:text-[50px] lg:leading-[45px] ${className}`}
    >
      {children}
    </Typography>
  )
}
