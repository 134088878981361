import { ImageDetailTileResult } from 'src/components/layouts/Page/SanityContentPage/types'

import ImageDetailTile from '../ImageDetailTile/ImageDetailTile'

type ImageDetailTileSectionProps = {
  tiles: ImageDetailTileResult[]
}

const ImageDetailTileSection = ({ tiles }: ImageDetailTileSectionProps) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 md:flex-wrap flex-col md:flex-row gap-5 lg:gap-10 auto-rows-fr'>
      {tiles.map((tile, idx) => (
        <ImageDetailTile key={`tile_${idx}`} {...tile} />
      ))}
    </div>
  )
}

export default ImageDetailTileSection
