import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import { useMemo } from 'react'

import {
  Heading4,
  Paragraph
} from 'src/components/atoms/DesignSystem/Typography/Typography'
import { Image } from 'src/components/atoms/Image'
import { Heading2 } from 'src/components/atoms/Typography'
import {
  CmsCtaButton,
  CmsCtaButtonProps
} from 'src/components/molecules/CmsCtaButton'

import { getSanityImageUrl } from 'src/utils/sanity'

export interface TileProps {
  heading?: string
  description?: string
  icon?: SanityImageSource
  buttons?: CmsCtaButtonProps[] | JSX.Element
}

export interface TilesProps {
  heading?: string
  description?: string
  color: string
  tiles: TileProps[]
}

const getTileColourClasses = (color: string) => {
  switch (color) {
    case 'linen':
      return 'bg-linen'
    case 'aquaSpring':
      return 'bg-aquaSpring'
    case 'white':
    default:
      return 'bg-white'
  }
}

export const TilesSection = ({ tiles, color, heading }: TilesProps) => {
  const renderedTile = (tile: TileProps, idx: number) => {
    return (
      <div
        key={`tile_${idx}`}
        className={`${getTileColourClasses(
          color
        )} p-4 lg:p-8 rounded flex flex-col gap-5`}
      >
        {tile.icon && (
          <div className='w-[60px] h-[60px] xl:w-[80px] xl:h-[80px]'>
            <Image
              alt=''
              src={getSanityImageUrl(tile.icon)}
              height={80}
              width={80}
              className='object-contain'
            />
          </div>
        )}
        <div>
          {tile.heading && (
            <Heading4 className='pb-[10px]'>{tile.heading}</Heading4>
          )}
          <Paragraph className='font-medium'>{tile.description}</Paragraph>
        </div>
        {Array.isArray(tile.buttons)
          ? tile.buttons?.map((button, ctaidx) => (
              <CmsCtaButton
                key={button.text}
                {...button}
                color={ctaidx === 0 ? 'primary' : 'white'}
              />
            ))
          : tile.buttons}
      </div>
    )
  }

  const gridClasses = useMemo(() => {
    switch (tiles.length) {
      case 1:
        return 'lg:grid-cols-1'
      case 2:
        return 'lg:grid-cols-2'
      case 3:
      case 6:
        return 'lg:grid-cols-3'
      default:
        return 'lg:grid-cols-2'
    }
  }, [tiles])

  return (
    <div>
      {heading ? <Heading2 className='pb-10'>{heading}</Heading2> : null}
      <div className={`grid grid-cols-1 ${gridClasses} gap-5 md:gap-10`}>
        {tiles.map((tile, idx) => renderedTile(tile, idx))}
      </div>
    </div>
  )
}
